import { Pipe } from '@angular/core'

@Pipe({
    name: 'htmlEnhance',
    standalone: true,
})
export class HtmlEnhancePipe {
    transform(value: string): string {
        try {
            // Create a DOM parser
            const parser = new DOMParser()
            const doc = parser.parseFromString(value, 'text/html')

            // Get all img tags
            const images = doc.querySelectorAll('img')
            console.log(images)

            // Add img-fluid class to each img tag
            images.forEach((img) => {
                img.classList.add('img-fluid')
            })

            // Return the enhanced HTML as a string
            return doc.body.innerHTML
        } catch (ex) {
            console.error(ex)
            return value
        }
    }
}
