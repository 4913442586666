<form class="login-container col">
    <h3 class="text-center mt-5 mb-2">Welcome to Brandfirm</h3>

    <div class="card card-body bg-light">
        <img [src]="userImg" class="avatar img-fluid" />

        <div class="d-flex justify-content-center">
            <asl-google-signin-button type="standard" size="large" shape="pill"></asl-google-signin-button>
        </div>
    </div>
</form>
