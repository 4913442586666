<div class="modal-body mx-2">
    <label class="font-weight-bold" *ngIf="title">
        {{ title }}
    </label>

    <div class="my-4">
        <div class="w-100 d-flex text-center">
            <div
                class="w-100 border-top border-bottom border-left py-2 rating-clickable"
                style="cursor: pointer"
                *ngFor="let num of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; let last = last"
                [class.border-right]="last"
                [class.bg-secondary]="report[field] === num"
                (click)="setRating(num)"
            >
                {{ num }}
            </div>
        </div>
        <div class="w-100 d-flex justify-content-between mt-2">
            <small class="text-muted">Zeer onwaarschijnlijk</small>
            <small class="text-muted">Zeer waarschijnlijk</small>
        </div>
    </div>

    <!-- <div class="row">
        <div
        class="col-2 mt-3"
        *ngFor="let num of [1, 2, 3, 4, 5]; let first = first"
        [class.offset-1]="first"
        (click)="setRating(num)"
        >
        <i class="fa-light fa-star" [class.fa-2x]="true" [class.fa-fw]="true" *ngIf="report[field] < num"></i>
        <i class="fa-solid fa-star" [class.fa-2x]="true" [class.fa-fw]="true" *ngIf="report[field] >= num"></i>
    </div>
</div> -->
</div>

<div class="modal-footer bg-info text-white" *ngIf="preview">
    <label>
        <small><i>Preview modus, result will not be saved!</i></small>
    </label>
</div>
