import { Component, OnInit } from '@angular/core'
import { AuthService } from 'app/_services/auth.service'
import { UserService } from 'app/_services/user.service'
import { environment } from 'environments/environment'
import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'

@Component({
    selector: 'app-auth-login',
    templateUrl: `./login.component.html`,
    styles: [
        `
            .login-container {
                max-width: 450px;
                margin-left: auto;
                margin-right: auto;
            }
        `,
    ],
    standalone: true,
    imports: [ReactiveFormsModule, FormsModule, GoogleSigninButtonModule],
})
export class LoginComponent {
    userImg = '/assets/placeholder/user_image.profile.png'

    environment = environment

    constructor(private userService: UserService, private authService: AuthService) {}

    ngOnInit() {
        this.authService.authSource.subscribe((authData) => {
            if (authData) this.userService.navigateHome()
        })
    }
}
