import { Routes } from '@angular/router'
import { LoginComponent } from './public/login/login.component'
import { ReportViewComponent } from './public/report-view/report-view.component'
import { AuthGuardService } from './_services/auth-guard.service'

export const ROUTES: Routes = [
    {
        path: 'main',
        canActivate: [AuthGuardService],
        data: { role: ['brandfirm-employee', 'brandfirm-admin'] },
        loadChildren: () => import('./main/main.routes').then((m) => m.MAIN_ROUTES),
    },
    {
        path: 'super',
        canActivate: [AuthGuardService],
        data: { role: ['brandfirm-admin'] },
        loadChildren: () => import('./super/super.routes').then((m) => m.SUPER_ROUTES),
    },
    {
        path: 'statistics',
        canActivate: [AuthGuardService],
        data: { role: ['brandfirm-admin'] },
        loadChildren: () => import('./statistics/statistics.routes').then((m) => m.STATISTICS_ROUTES),
    },
    {
        path: 'supplier',
        canActivate: [AuthGuardService],
        data: { role: ['brandfirm-admin', 'link-supplier'] },
        loadChildren: () => import('./supplier/supplier.routes').then((m) => m.SUPPLIER_ROUTES),
    },
    {
        path: 'public/link-supplier/:linkSupplierId',
        loadComponent: () => import('./public/link-supplier-view/link-supplier-view.component').then((m) => m.LinkSupplierViewComponent),
    },
    { path: 'report/:reportId', component: ReportViewComponent },
    { path: 'report/:reportId/:simplicateId', component: ReportViewComponent },
    {
        path: '**',
        component: LoginComponent,
    },
]
